<template>
  <div class="trips-groups-list">
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold">Trip groups</div>
      <v-spacer></v-spacer>
      <v-btn @click="modal = true" depressed class="primary" width="136" height="44">Add group</v-btn>
    </div>
    <v-card
      v-for="(item, j) in data"
      :key="item.id"
      @click="openItem(item.id)"
      class="pa-4 pr-6 d-flex align-center mt-3"
      width="100%"
      outlined
    >
      <div class="font-weight-bold width100 text-truncate" style="max-width: 500px">{{ item.title_en }}</div>
      <v-spacer></v-spacer>
      <div class="text--text">{{ item.filters.length }} filters available</div>
      <v-spacer></v-spacer>
      <div class="text--text mt-1">
        <v-icon color="primary" class="pb-1 pr-1">mdi-{{ item.active ? 'check' : 'close' }}</v-icon>
        {{ item.active ? 'Active' : 'Inactive' }}
      </div>
      <v-spacer></v-spacer>
      <v-card outlined class="rounded d-flex align-center">
        <v-btn @click.stop="openModal(item, j)" width="48" height="38" icon>
          <v-img contain width="24" height="24" src="@/assets/icon/edit.svg"></v-img>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn @click.stop="openDelete(item, j)" width="48" height="38" icon>
          <v-img contain width="24" height="24" src="@/assets/icon/delete.svg"></v-img>
        </v-btn>
      </v-card>
    </v-card>
    <v-dialog v-model="modal" max-width="700">
      <v-card>
        <div class="font-weight-bold py-4 text-center">{{ modalData.id ? 'Edit ' : 'Add ' }}trip group</div>
        <v-divider></v-divider>
        <div class="px-10">
          <div class="my-5">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.description_en"
                :error-messages="descEnErrors"
                placeholder="Enter description (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="descArErrors"
                v-model="modalData.description_ar"
                placeholder="Enter description (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="36"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-checkbox class="py-2 mt-0" color="success" v-model="modalData.active" hide-details>
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">Active</div>
              </div>
            </template>
          </v-checkbox>
        </div>
        <v-divider class="mt-5"></v-divider>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="modalData.id ? updateItem() : addItem()" depressed class="primary ms-3 rounded" width="106" height="30">
            {{ modalData.id ? 'Save' : 'Add' }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDelete" max-width="480">
      <v-card class="px-4 pt-4 pb-5">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to delete {{ modalData.title_en }} trip group?</div>
        <div class="text--text">All trips related to this group will be saved</div>
        <div class="text-end mt-5">
          <v-btn @click="modalDelete = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="removeItem" depressed class="error ms-3 rounded" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalDelete: false,
      modalData: {
        active: false,
      },
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripsGroupsList', '');
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group add successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group item remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openItem(id) {
      this.$router.push(`/trip-groups/${id}`);
    },
    openModal(i, j) {
      this.modalData = Object.assign({}, i);
      this.modalData.pos = j;
      this.modal = true;
    },
    openDelete(i, j) {
      this.modalData = Object.assign({}, i);
      this.modalData.pos = j;
      this.modalDelete = true;
    },
    cleanModal() {
      this.error = [];
      this.modalData = {
        active: false,
      };
    },
    async addItem() {
      this.error = [];
      await this.$store
        .dispatch('createTripsGroup', this.modalData)
        .then(() => {
          this.notifi('create');
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateItem() {
      this.error = [];
      await this.$store
        .dispatch('updateTripsGroupItem', this.modalData)
        .then(() => {
          this.data[this.modalData.pos] = this.item;
          this.notifi('update');
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeItem() {
      this.error = [];
      await this.$store
        .dispatch('deleteTripsGroupItem', this.modalData.id)
        .then(() => {
          this.data.splice(this.modalData.pos, 1);
          this.modalDelete = false;
          this.notifi('remove');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    modal(val) {
      val ? '' : this.cleanModal();
    },
    modalDelete(val) {
      val ? '' : this.cleanModal();
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsGroupsList;
    },
    item() {
      return this.$store.getters.tripsGroup;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    descEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    descArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsGroupsList');
  },
};
</script>
